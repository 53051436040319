import { jsPDF } from "jspdf";
import "jspdf-autotable";
import vuePDF from "vue-pdf";

import Moment from "moment";

export default {
  name: "TDepositAccountDetailAccountStatement",
  data() {
    return {
      property: {
        animation: {
          checkingDepositAccount: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        modal: {
          showModalSearchDepositAccount: false,
          showModalCheckingResultDepositAccount: false,
        },
        listElement: {
          depositAccountStatement: {
            message: "",
            downloading: false,
          },
          checkingDepositAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          dateFrom: "",
          dateTo: "",
          sorting: "",
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      session: {
        checkingDepositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      dataForm: {
        checkingDepositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
        dateFrom: "",
        dateTo: "",
        depositAccount: { 
          header: {
            accountNumber: 0,
            nominal: 0,
            name: "",
            allocatedFundDate: 0,
            dueDate: 0,
            officeCode: 0,
            officeName: "",
            officeAddress: "",
            startDate: "",
            endDate: "",
            cifAddress: "",
          },
        },
      },
      options: {
        urutkanBerdasarkan: [
          { value: "ASC", text: "Terlama - Terbaru" },
          { value: "DESC", text: "Terbaru - Terlama" },
        ],
      },
      table: {
        header: {
          transaction: [
            {
              key: "transactionDate",
              label: "Tanggal",
            },
            {
              key: "documentProofNumber",
              label: "No Bukti Dokumen",
            },
            {
              key: "transactionCode",
              label: "No Transaksi",
            },
            {
              key: "description",
              label: "Keterangan",
            },
            {
              key: "mutationDebit",
              label: "Mutasi Debet",
            },
            {
              key: "mutationCredit",
              label: "Mutasi Kredit",
            },
            {
              key: "endBalance",
              label: "Saldo Akhir",
            },
          ],
        },
        data: {
          transaction: [],
          checkingDepositAccount: [],
        },
      },
    };
  },
  methods: {
    Moment: Moment,
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        depositAccountNumber: this.dataForm.checkingDepositAccount
          .accountNumber,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        sorting: this.property.filterDto.sorting,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/account-statement/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Rekening_Koran_Deposito.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    handleCloseModalCheckingDepositAccount() {
      this.property.modal.showModalCheckingDepositAccount = false;
      this.handleClearModalCheckingDepositAccount();
    },
    async handleChangePaginationDepositAccount(event) {
      this.table.data.checkingDepositAccount = [];
      this.property.listElement.checkingDepositAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: this.session.checkingDepositAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingDepositAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.checkingDepositAccount.perPage,
            dateFrom: "",
            dateTo: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.checkingDepositAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.data.checkingDepositAccount =
              resp.data.data.pageResponse.content;
          } else {
            this.handleFailedGetDataDepositAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.checkingDepositAccount.downloading = false;
        }, timeout);
      }
    },
    handleCatchErrorDepositAccount(error) {
      this.dataForm.checkingDepositAccount.accountNumber = this.session.depositAccount.accountNumber;
      this.dataForm.checkingDepositAccount.cifIdName = this.session.depositAccount.cifIdName;
      this.session.depositAccount.accountNumber = "";
      this.session.depositAccount.cifIdName = "";
      console.log(error.response);
      this.table.data.depositAccount = [];
      this.property.listElement.checkingDepositAccount.rows = 0;
      this.property.listElement.checkingDepositAccount.currentPage = 1;
      this.property.listElement.checkingDepositAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleFailedGetDataDepositAccount(resp) {
      this.table.data.checkingDepositAccount = [];
      this.property.listElement.checkingDepositAccount.rows = 0;
      this.property.listElement.checkingDepositAccount.message =
        resp.data.message;
    },
    async checkingAvailabilityDepositAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingDepositAccount.accountNumber = this.dataForm.checkingDepositAccount.accountNumber;
        this.session.checkingDepositAccount.cifIdName = this.dataForm.checkingDepositAccount.cifIdName;
        this.dataForm.checkingDepositAccount.accountNumber = "";
        this.dataForm.checkingDepositAccount.cifIdName = "";
        const timeout = 500;
        try {
          this.property.animation.checkingDepositAccount.isLoading = true;
          this.property.listElement.checkingDepositAccount.downloading = false;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "deposit-account/simple-list",
            payload: {
              accountNumber: this.session.checkingDepositAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingDepositAccount.cifIdName,
              dateFrom: "",
              dateTo: "",
              page: 0,
              size: this.property.listElement.checkingDepositAccount.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalSearchDepositAccount = false;
              this.property.modal.showModalCheckingResultDepositAccount = true;
              this.table.data.checkingDepositAccount =
                resp.data.data.pageResponse.content;
              this.property.listElement.checkingDepositAccount.rows =
                resp.data.data.pageResponse.totalElements;
            } else if (resp.data.code === "DATA_NOT_FOUND") {
              this.dataForm.checkingDepositAccount.accountNumber = this.session.checkingDepositAccount.accountNumber;
              this.dataForm.checkingDepositAccount.cifIdName = this.session.checkingDepositAccount.cifIdName;
              this.session.checkingDepositAccount.accountNumber = "";
              this.session.checkingDepositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `Nomor Rekening Tidak Tersedia!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.dataForm.checkingDepositAccount.accountNumber = this.session.checkingDepositAccount.accountNumber;
              this.dataForm.checkingDepositAccount.cifIdName = this.session.checkingDepositAccount.cifIdName;
              this.session.checkingDepositAccount.accountNumber = "";
              this.session.checkingDepositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
          this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
          this.session.depositAccount.accountNumber = "";
          this.session.depositAccount.cifIdName = "";
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingDepositAccount.isLoading = false;
          }, timeout);
        }
      }
    },
    handleClearModalResultCheckingDepositAccount() {
      this.table.data.checkingDepositAccount = [];
      this.property.listElement.checkingDepositAccount.rows = 0;
      this.property.listElement.checkingDepositAccount.perPage = 5;
      this.property.listElement.checkingDepositAccount.currentPage = 1;
    },
    handleClearModalCheckingDepositAccount() {
      this.property.modal.showModalSearchDepositAccount = false;
      this.property.filterDto.dateFrom = "";
      this.property.filterDto.dateTo = "";
    },
    getDepositAccount() {},
    async handleSearchDepositAccountDetailAccountStatement(accountNumber) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        const timeout = 500;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "deposit-account-detail/account-statement",
            payload: {
              depositAccountNumber: accountNumber,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              sorting: this.property.filterDto.sorting,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingResultDepositAccount = false;
              this.table.data.transaction = [];
              this.property.listElement.depositAccountStatement.downloading = true;
              this.dataForm.dateFrom = this.property.filterDto.dateFrom;
              this.dataForm.dateTo = this.property.filterDto.dateTo;
              this.property.filterDto.dateFrom = "";
              this.property.filterDto.dateTo = "";
              setTimeout(() => {
                this.dataForm.depositAccount.header.accountNumber =
                  resp.data.data.header.accountNumber;
                this.dataForm.depositAccount.header.name = resp.data.data.header
                  .qqName
                  ? `${resp.data.data.header.cifName} ${resp.data.data.header.qqCode} ${resp.data.data.header.qqName}`
                  : resp.data.data.header.cifName;
                this.dataForm.depositAccount.header.nominal =
                  resp.data.data.header.nominal;
                this.dataForm.depositAccount.header.allocatedFundDate =
                  resp.data.data.header.allocatedFundDate;
                this.dataForm.depositAccount.header.dueDate =
                  resp.data.data.header.dueDate;
                this.dataForm.depositAccount.header.officeName =
                  resp.data.data.header.officeName;
                this.dataForm.depositAccount.header.officeAddress =
                  resp.data.data.header.officeAddress;
                this.dataForm.depositAccount.header.cifAddress =
                  resp.data.data.header.cifAddress;
                if (resp.data.data.details) {
                  this.table.data.transaction = resp.data.data.details;
                  this.handlePreparePeriode();
                } else {
                  this.table.data.transaction = [];
                  this.property.listElement.depositAccountStatement.message =
                    "Belum Ada Transaksi";
                  this.dataForm.depositAccount.header.startDate = this.dataForm.dateFrom;
                  this.dataForm.depositAccount.header.endDate = this.dataForm.dateTo;
                }
              }, timeout);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
                timer: 2000,
              });
            }
          }, timeout);
        } catch (error) {
          console.log(error.response);
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
              timer: 2000,
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.depositAccountStatement.downloading = false;
          }, timeout);
        }
      }
    },
    handlePrintDepositAccountStatement() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.transaction.map((index) => {
        headerFinal.push(index.label);
      });
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.transaction.map((index) => {
        finalDataTable.push([
          `${Moment(index.transactionDate).format("LL")}`,
          index.documentProofNumber,
          index.transactionCode,
          index.description,
          `${
            index.mutationDebit
              ? index.mutationDebit
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.mutationCredit
              ? index.mutationCredit
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.endBalance
              ? index.endBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
        ]);
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc
        .setFontSize(10)
        .text(`Laporan Rekening Koran`, pageWidth / 2 - 1.1, 0.8);
      doc
        .setFontSize(9)
        .text(` ${this.dataForm.depositAccount.header.officeName}`, 0.55, 1.3);
      doc
        .setFontSize(9)
        .text(
          `${this.dataForm.depositAccount.header.officeAddress}`,
          0.55,
          1.5
        );
      doc
        .setFontSize(9)
        .text(
          `Nomor Rekening  :   ${this.dataForm.depositAccount.header.accountNumber}`,
          pageWidth / 2 + 1,
          1.3
        );

      doc
        .setFontSize(9)
        .text(
          `Nominal  :   ${
            this.dataForm.depositAccount.header.nominal
              ? this.dataForm.depositAccount.header.nominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : ""
          } `,
          pageWidth / 2 + 1,
          1.5
        );
      doc.setFontSize(9).text(`Kepada  Yth, `, 0.55, 1.8);
      doc
        .setFontSize(9)
        .text(
          `Tanggal Penempatan Dana  : ${Moment(
            this.dataForm.depositAccount.header.allocatedFundDate
          ).format("LL")} `,
          pageWidth / 2 + 1,
          1.7
        );

      doc
        .setFontSize(9)
        .text(`${this.dataForm.depositAccount.header.name}`, 0.55, 2.0);
      doc
        .setFontSize(9)
        .text(
          `Tanggal Jatuh Tempo  :   ${this.dataForm.depositAccount.header.dueDate} `,
          pageWidth / 2 + 1,
          1.9
        );
      doc
        .setFontSize(9)
        .text(`${this.dataForm.depositAccount.header.cifAddress}`, 0.55, 2.2);

      doc
        .setFontSize(8)
        .text(
          `Periode ${this.dataForm.depositAccount.header.startDate} s/d ${this.dataForm.depositAccount.header.endDate}`,
          pageWidth / 2 - 1,
          2.8
        );

      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            font: "courier",
            fontSize: 6,
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 6.4,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 3.0 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    handlePreparePeriode() {
      if (this.table.data.transaction !== []) {
        this.dataForm.depositAccount.header.startDate = ` ${Moment(
          this.table.data.transaction[0].transactionDate
        ).format("LL")}`;

        this.dataForm.depositAccount.header.endDate = ` ${Moment(
          this.table.data.transaction[this.table.data.transaction.length - 1]
            .transactionDate
        ).format("LL")}`;
      }
    },
    async handleSortingDepositAccountStatement(event) {
      this.table.data.transaction = [];
      this.property.listElement.depositAccountStatement.downloading = true;

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "deposit-account-detail/account-statement",
            payload: {
              depositAccountNumber: this.dataForm.depositAccount.header.accountNumber,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              sorting: event,
            },
          });
          if (resp.data.code === "SUCCESS") {
            if (resp.data.data.details) {
              this.table.data.transaction = resp.data.data.details;
              this.handlePreparePeriode();
            } else {
              this.table.data.transaction = [];
              this.property.listElement.depositAccountStatement.message =
                "Tidak Ada Transaksi";
              this.dataForm.depositAccount.header.startDate = this.dataForm.dateFrom;
              this.dataForm.depositAccount.header.endDate = this.dataForm.dateTo;
            }
          } else {
            this.table.data.transaction = [];
            this.property.listElement.depositAccountStatement.message =
              resp.data.message;
          }
        } catch (error) {
          this.handleCatchErrorSortingDepositAccountStatement(error);
        } finally {
          setTimeout(() => {
            this.property.listElement.depositAccountStatement.downloading = false;
          }, 500);
        }
      }
    },
    handleCatchErrorSortingDepositAccountStatement(error) {
      console.log(error.response);
      this.table.data.transaction = [];
      this.property.listElement.depositAccountStatement.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handlePrepareDepositAccountStatement() {
      this.property.filterDto.sorting = "DESC";
      this.property.listElement.depositAccountStatement.message =
        "Pilih Nomor Rekening Terlebih Dahulu";
    },
  },
  mounted() {
    this.handlePrepareDepositAccountStatement();
    Moment.locale("id");
  },
};
